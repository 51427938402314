<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div v-if="value && value.length">
      <ul class="watchers-panel">
        <li
            :key="item.id"
            class="watcher pl-0 outline-none"
            v-for="item in value"
        >
          <el-tooltip
              :content="item.name"
              class="item"
              effect="dark"
              placement="top-start"
          >
            <img
                :alt="item.name"
                :src="'data:image/jpeg;base64,' + item.avatar"
                class="avatar--ifImage"
                v-if="item.avatar"
            >
            <span
                class="avatar--ifNotImage"
                v-else
            >
                          {{ abbreviation(item.name) }}
                        </span>
          </el-tooltip>
        </li>
      </ul>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';
import {abbreviation} from '../../../../../helpers/functions';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {BaseFormItem},
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.watchers-panel {
  margin: 0;
  list-style-type: none;
  padding: 0;

  .watcher {
    display: inline-block;
    margin-right: 5px;
  }
}

.avatar--ifImage, .avatar--ifNotImage {
  height: 25px;
  width: 25px;
}
</style>
